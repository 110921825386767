import React, { useState } from "react";
import { Table, TableFooter, TableBody, TablePagination, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel } from "@mui/material";
import Spinner from "./Spinner";
import physiciansIcon from "../img/physician-icon.png";
import Button from "@mui/material/Button";

const MUITable = ({ data, columns, isLoading, filterDate, attachLabRecordFilterDate, setSelectedRowData, isTextWrap }) => {
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(columnId);
  };

  const getSorting = (columnId) => (a, b) => {
    let valueA, valueB;

    if (columnId === "diagnosis") {
      valueA = a.diagnosis?.medicalCondition ?? "";
      valueB = b.diagnosis?.medicalCondition ?? "";
    } else {
      valueA = a[columnId] ?? "";
      valueB = b[columnId] ?? "";
    }

    if (columnId === "diagnosis") {
      return order === "asc" ? valueA.localeCompare(valueB, undefined, { numeric: true }) : valueB.localeCompare(valueA, undefined, { numeric: true });
    }

    if (valueA === null && valueB !== null) {
      return -1;
    } else if (valueA !== null && valueB === null) {
      return 1;
    } else if (valueA === null && valueB === null) {
      return 0;
    }

    if (order === "asc") {
      return valueA < valueB ? -1 : 1;
    } else {
      return valueA > valueB ? -1 : 1;
    }
  };

  const filterData = (selectedDate) => {
    if (selectedDate === "" || selectedDate == undefined) {
      return data;
    } 
    if (attachLabRecordFilterDate ) {
      return data.filter((item) => {
        return item.visitDate === selectedDate;
      });
    } if (attachLabRecordFilterDate == undefined) {
      return data.filter((item) => {
        return item.hospitalVisitDate === selectedDate;
      });
    }
  };
  const filteredData = filterData(filterDate ? filterDate : attachLabRecordFilterDate);

  const sortedData = [...filteredData].sort(getSorting(orderBy));

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, sortedData.length - page * rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onCellClickHandler = (rowData, columnName) => {
    if (setSelectedRowData) {
      setSelectedRowData({ rowData, columnName });
    }
  };
  return (
    <div className="MUI-table-wrapper">
      <TableContainer component={Paper}>
        <Table
          dense={true}
          className="MUI-table"
          sx={{ textWrap: "nowrap" }}
        >
          <TableHead style={{ backgroundColor: "#e0e0e0" }}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => handleSort(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!!isLoading ? (
            <TableRow sx={{ textAlign: "center" }}>
              <TableCell colSpan={8}>
                <Spinner />
              </TableCell>
            </TableRow>
          ) : (
            <>
              <TableBody>
                {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  return (
                    <>
                      <TableRow
                        key={index}
                        className={index % 2 === 0 ? "evenRow" : "oddRow"}
                        style={isTextWrap ? { whiteSpace: "normal" } : { whiteSpace: "nowrap" }}
                      >
                        {columns.map((column, columnIndex) => {
                          if (column.id === "medicineInfo") {
                            return (
                              <TableCell
                                key={column.id}
                                onClick={() => onCellClickHandler(row, column.id)}
                                className={`${!!column.isLink ? "visitLink" : ""}`}
                              >
                                {row?.medicineInfo.length === 0 && <span className="test-done not-available">N/A</span>}
                                {row.medicineInfo.map((medicine, i) => (
                                  <div
                                    key={i}
                                    className="test-done"
                                  >
                                    <p>{`${medicine.medicineName}`}</p>
                                  </div>
                                ))}
                              </TableCell>
                            );
                          }
                          if (column.id === "testName") {
                            return (
                              <TableCell
                                key={column.id}
                                onClick={() => onCellClickHandler(row, column.id)}
                                className={`${!!column.isLink ? "visitLink" : ""}`}
                              >
                                {row.testName && row?.testName.length === 0 && <span className="test-done not-available">N/A</span>}
                                {Array.isArray(row?.testName) && row?.testName.map((test, i) => (
                                  <div
                                    className="test-done"
                                    key={i}
                                  >
                                    <p>{`${test}`}</p>
                                  </div>
                                ))}
                              </TableCell>
                            );
                          }
                          if (column.id === "diagnosis") {
                            return (
                              <TableCell
                                onClick={() => onCellClickHandler(row, column.id)}
                                key={column.id}
                                className={`${!!column.isLink ? "visitLink" : ""}`}
                              >
                                {row.diagnosis?.medicalCondition || <span className="test-done not-available">N/A</span>}
                              </TableCell>
                            );
                          }
                          if (column.id === "isOutPatient") {
                            return (
                              <TableCell
                                onClick={() => onCellClickHandler(row, column.id)}
                                key={column.id}
                                className={`${!!column.isLink ? "visitLink" : ""}`}
                              >
                                {row.isOutPatient ? "Out-Patient" : "In-Patient"}
                              </TableCell>
                            );
                          }
                          if (columnIndex === 0 && row.sampleData) {
                            return (
                              <TableCell
                                onClick={() => onCellClickHandler(row, column.id)}
                                key={column.id}
                                style={{ position: "relative" }}
                                className={`${!!column.isLink ? "visitLink" : ""}`}
                              >
                                <div className="ribbon ribbon-top-left">
                                  <span>Sample</span>
                                </div>
                                {row[column.id]}
                              </TableCell>
                            );
                          }
                          if (column.id === "testDone") {
                            const parentTestNames = row[column.id].map((test) => test.parentTestName);
                            return (
                              <TableCell
                                onClick={() => onCellClickHandler(row, column.id)}
                                key={column.id}
                              >
                                {parentTestNames.length > 0 ? (
                                  <>
                                    <span className="test-done">
                                      <span>{parentTestNames[0]} & Others</span>
                                    </span>
                                  </>
                                ) : (
                                  <span className="test-done not-available">N/A</span>
                                )}
                              </TableCell>
                            );
                          }
                          if (column.id === "doctorName") {
                            return (
                              <TableCell
                                onClick={() => onCellClickHandler(row, column.id)}
                                key={column.id}
                                className="report-icon"
                              >
                                {row[column.id] ? (
                                  <>
                                    <img
                                      className="ellipse-2"
                                      alt="Ellipse"
                                      src={physiciansIcon}
                                    />
                                    {row[column.id]}
                                  </>
                                ) : (
                                  <span className="test-done not-available">N/A</span>
                                )}
                              </TableCell>
                            );
                          }
                          if (column.id === "customColumn") {
                            return (
                              <TableCell>
                                {row ? (
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="success"
                                    onClick={() => onCellClickHandler(row, column.id)}
                                  >
                                    {!row.doctorReviewFlag && !row.patientReviewFlag && "Doctor Review"}
                                    {row.doctorReviewFlag && !row.patientReviewFlag && "Patient Action"}
                                    {row.doctorReviewFlag && row.patientReviewFlag && "Complete"}
                                  </Button>
                                ) : (
                                  <span className="test-done not-available">N/A</span>
                                )}
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell
                              onClick={() => onCellClickHandler(row, column.id)}
                              key={column.id}
                              className={`${!!column.isLink ? "visitLink report-icon" : "report-icon"}`}
                            >
                              {row[column.id] ? (
                                <>
                                  {/* {!!column.ReportIcon && (
                                    <img
                                      className="ellipse-2"
                                      alt="Ellipse"
                                      src={column.ReportIcon}
                                    />
                                  )} */}
                                   {column.id === "prescriptionLink"
                                      ? "View Prescription Report"
                                      : column.id === "link"
                                      ? "View Test Report"
                                      : column.id === "feedback"
                                      ? "Feedback"
                                      : column.id === "viewPayment"
                                      ? "View Detail"
                                      : row[column.id]}
                                </>
                              ) : (
                                <span className="test-done not-available">N/A</span>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={columns.length} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                    colSpan={columns.length}
                    count={sortedData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default MUITable;
