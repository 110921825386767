import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { GrRefresh } from "react-icons/gr";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { strings } from "../../constants/strings";
import dummyReport from "../../img/dummyReport.png";
import iconAttachment from "../../img/icon-attachment.png";
import PreventiveRecord from "./PreventiveReports";
import physiciansIcon from "../../img/physician-icon.png";
import ReviewsTabs from "../../screens/ElementLabTestRecords/ReviewsTabs";
import SampleIllustration from "../../shared/SampleIllustration";
import Spinner from "../../shared/Spinner";
import InfoModel from "../../shared/simpleModel/InfoModel";
import MUITable from "../../shared/MUITable";
import FilterListIcon from '@mui/icons-material/FilterList';
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const columns = [
  {
    id: "id",
    label: "Report Id",
  },
  {
    id: "visitId",
    label: "Visit Id",
  },
  {
    id: "hospitalVisitDate",
    label: "Hospital Date",
  },
  {
    id: "labName",
    label: "Lab Name"
  },
  {
    id: "collectionDate",
    label: "Collection Date",
  },
  {
    id: "medicalComplaint",
    label: "Test Done",
  },
  {
    id: "doctorName",
    label: "Attending Physician",
  },
  {
    id: "prescriptionLink",
    label: "Prescription Report",
    isLink: true,
    ReportIcon: iconAttachment,
  },
  {
    id: "link",
    label: "Test Report",
    isLink: true,
    ReportIcon: iconAttachment,
  },
  {
    id: "customColumn",
    label: "Review Status",
  },
  // {
  //   id: "status",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Status",
  // },
];

const columnsWitoutPrescription = [
  {
    id: "id",
    label: "Report Id",
  },
  // {
  //   id: "visitId",
  //   label: "Visit Id",
  // },
  // {
  //   id: "labName",
  //   label: "Hospital Name"
  // },
  // {
  //   id: "hospitalVisitDate",
  //   label: "Hospital Date",
  // },
  {
    id: "collectionDate",
    label: "Collection Date",
  },
  {
    id: "medicalComplaint",
    label: "Test Done",
  },
  {
    id: "doctorName",
    label: "Attending Physician",
  },
  // {
  //   id: "prescriptionLink",
  //   label: "Prescription Report",
  //   isLink: true,
  //   ReportIcon: iconAttachment,
  // },
  {
    id: "link",
    label: "Test Report",
    isLink: true,
    ReportIcon: iconAttachment,
  },
  {
    id: "customColumn",
    label: "Review Status",
  },
  // {
  //   id: "status",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Status",
  // },
];

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return <></>;
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function  EnhancedTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const [recordsListData, setRecordsListData] = useState([]);
  const [withVisitDate, setWithVisitDate] = useState([]);
  const [withoutVisitDate, setWithoutVisitDate] = useState([]);
  const [testResult, setTestResult] = useState();
  const [testResultData, setTestResultData] = useState();
  const [showExplaination, setShowExplaination] = useState(false);
  const [showNormalReport, setShowNormalReport] = useState();
  const [createdOnDate, setCreatedOnDate] = useState();
  const [statusColor, setStatusColor] = useState();
  const [filterDate, setFilterDate] = useState("");
  const [filterLabName, setFilterLabName] = useState("");
  const [selectedReport, setSelectedReport] = useState([]);
  const [isReviewFormOpen, setIsReviewFormOpen] = useState(false);
  const [isDummyData, setIsDummyData] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [allergies, setAllergies] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [showTextField, setShowTextField] = useState(false);
  const [showDateField, setshowDateField] = useState(false);
  const [familyInfo, setFamilyInfo] = useState(null);
  const [familyRelation, setFamilyRelation] = useState(null);

  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };

  const rows = [];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    setStatusColor(name);
    setSelected([name]);
  };

  const getTestRecordsList = async () => {
    setIsLoading(true);
    await AxiosInstance.get(`test-report/list`).then((data) => {
      console.log("test report " , data);
      setTestResult(data);
      setIsDummyData(data[0].sampleData);
      setFilterDate("");
      setFilterLabName("");
      setShowExplaination(false);
      const testReportList = data?.map((obj) => {
        return {
          ...obj.testReport,
          sampleData: obj.sampleData,
          status: obj.testResult.findIndex((obj) => {
            if (obj.anomaly == "false") {
              return false;
            } else {
              return true;
            }
          }),
          medicalComplaint: obj.medicalComplaint,
        };
      });
      setRecordsListData(testReportList);
      setIsLoading(false);
      const withDate = testReportList.filter(item => item.visitId !== null);
      const withoutDate = testReportList.filter(item => item.visitId === null);
      setWithVisitDate(withDate);
      setWithoutVisitDate(withoutDate);
    });
  };

  const showTestResults = (row) => {
    setCreatedOnDate(row?.createdOn);
    setShowExplaination(true);
    testResult?.find((result) => {
      if (result.testReport.id == row.id) {
        let testResult = result;
        setTestResultData(testResult);
      }
    });
    if (row.status == -1) {
      setShowNormalReport(true);
    } else {
      setShowNormalReport(false);
    }
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // let token = getAccessToken();
  let token = window.sessionStorage.getItem("token");

  let myHeaders = new Headers();
  myHeaders.append("accept", "multipart/mixed");
  myHeaders.append("Authorization", "Bearer " + token);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const downloadReport = (url) => {
    setIsLoadingDoc(true);
    const downloadApi = "test-report/download";
    fetch(`${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${url}`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        // toast.success("File Downloaded successfully");
        openModal();
        const contentDispositionHeader = response.headers.get("Content-Disposition");
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDispositionHeader);
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              // const a = document.createElement("a");
              // a.href = blobUrl;
              // a.download = filename;
              // // a.click();
              // // window.URL.revokeObjectURL(blobUrl);
              setImageUrl(blobUrl);
              setIsLoadingDoc(false);
            });
          } else {
            toast.error("Filename not found in Content-Disposition header");
            setIsLoadingDoc(false);
          }
        } else {
          toast.error("Content-Disposition header not found in the response");
          setIsLoadingDoc(false);
        }
      })
      .catch((error) => {
        toast.error("Error fetching or downloading the file:", error);
        setIsLoadingDoc(false);
      });
  };

  const downloadPrescription = (url) => {
    if (url === null) {
      setIsLoadingDoc(false);
    } else {
    setIsLoadingDoc(true);
    const downloadApi = "prescription/download/";
    fetch(`${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${url}`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        // toast.success("File Downloaded successfully");
        openModal();
        const contentDispositionHeader = response.headers.get("Content-Disposition");
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDispositionHeader);
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              // const a = document.createElement("a");
              // a.href = blobUrl;
              // a.download = filename;
              // // a.click();
              // // window.URL.revokeObjectURL(blobUrl);
              setImageUrl(blobUrl);
              setIsLoadingDoc(false);
            });
          } else {
            toast.error("Filename not found in Content-Disposition header");
            setIsLoadingDoc(false);
          }
        } else {
          toast.error("Content-Disposition header not found in the response");
          setIsLoadingDoc(false);
        }
      })
      .catch((error) => {
        toast.error("Error fetching or downloading the file:", error);
        setIsLoadingDoc(false);
      });
    }
  };

  const filterData = (selectedDate) => {
    if (selectedDate === "") {
      return withVisitDate;
    } else {
      return withVisitDate.filter((item) => item.collectionDate === selectedDate);
    }
  };

  const handleDateChange = (event) => {
    setShowExplaination(false);
    const selectedDate = event.target.value;
    setFilterDate(selectedDate);
  };

  const filteredData = filterData(filterDate);

  filteredData?.sort(function (a, b) {
    let keyA = new Date(a.collectionDate),
      keyB = new Date(b.collectionDate);
    if (keyA < keyB) return 1;
    if (keyA > keyB) return -1;
    return 0;
  });

  const handleLabNameChange = (event) => {
    setShowExplaination(false);
    const labName = event.target.value;
    setFilterLabName(labName);
  };

 const filterLab = (labname) => {
   const lowercaseLabName = labname?.toLowerCase();
   if (lowercaseLabName === "") {
     return withVisitDate;
   } else {
     const filteredLab = withVisitDate.filter((item) => item?.labName?.toLowerCase().includes(lowercaseLabName));
     return filteredLab;
   }
 };
  const FilterLabName = filterLab(filterLabName);

  useEffect(() => {
    getTestRecordsList();
    AxiosInstance.get("form/personalFamilyHistory")
      .then((response) => {
        const allergiesArray = JSON.parse(response.allergies);
        setAllergies(allergiesArray);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const visibleRows = useMemo(() => stableSort(filteredData, getComparator(order, orderBy)), [filteredData, order, orderBy]);
  const visibleRows2 = useMemo(() => stableSort(FilterLabName, getComparator(order, orderBy)), [FilterLabName, order, orderBy]);
  const testReviewHandler = (row) => {
    setSelectedReport(row);
    setIsReviewFormOpen(true);
  };

  useEffect(() => {
    if (!!selectedRowData && selectedRowData.columnName === "prescriptionLink") {
      downloadPrescription(selectedRowData.rowData.prescriptionLink);
    } else if (!!selectedRowData && selectedRowData.columnName === "link") {
      downloadReport(selectedRowData.rowData.link);
    } else if (!!selectedRowData && selectedRowData.columnName === "customColumn") {
      testReviewHandler(selectedRowData.rowData);
    }
  }, [selectedRowData]);

   const handleLabNameFilterChange = (event) => {
     setShowTextField(event.target.checked);
   };
  const handleDateFilterChange = (event) => {
    setshowDateField(event.target.checked)
  }
     useEffect(() => {
       AxiosInstance.get("profile/get")
         .then((response) => {
           setFamilyInfo(response?.profile);
         })
         .catch((error) => {
           console.error("Error:", error);
         });
        AxiosInstance.get("/family/getPatientFamilyProfile")
          .then((response) => {
            setFamilyRelation(response);
          })
          .catch((error) => {
            console.log(error);
          });
        
     }, []);

    console.log("familyRelation: ", familyRelation , visibleRows2 , withVisitDate , withoutVisitDate);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs value={currentTabIndex} onChange={handleTabChange} sx={{ backgroundColor: "steelblue" }}>
          <Tab
            label="Test Reports"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 0 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
          <Tab
            label="Preventive Reports"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 1 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
        </Tabs>
        <br />
        {familyRelation?.relationShipWithUser != "wife" && familyRelation != null && (
          <>
            <h4 style={{ color: "blue" }}>
              The health Insights/Information presented below is for your child{" "}
              <span style={{ fontSize: "larger" }}>{familyInfo?.firstName && familyInfo.firstName.charAt(0).toUpperCase() + familyInfo.firstName.slice(1).toLowerCase()}</span>
            </h4>
            <br />
          </>
        )}
        {currentTabIndex === 0 && <Box sx={{ p: 3 }}>{/* <MedicalHistory></MedicalHistory> */}</Box>}
        {currentTabIndex === 1 && (
          <Box sx={{ p: 3 }}>
            <PreventiveRecord></PreventiveRecord>
          </Box>
        )}
        {currentTabIndex == 0 && (
          <>
            <Row>
              {withVisitDate.length > 0 && <Col lg={7}>
               <h4>Test Report associated with Prescription </h4>
              </Col>}
              {withVisitDate.length > 0 ? (
                <Col lg={5} style={{ marginBottom: "1%", display: "flex", justifyContent: "space-between" }}>
                  <div style={{ marginInlineStart: "auto" }}>
                    <a href="#" style={{ fontSize: "20px", color: "black", border: "1px solid lightgrey", padding: "10px" }} onClick={() => setShowFilters(!showFilters)}>
                      Filters <FilterListIcon />
                    </a>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <br />
                  {showFilters && (
                    <div style={{ border: "1px solid lightgrey", padding: "10px", marginTop: "-10px" }}>
                      <input type="checkbox" id="dateFilter" name="dateFilter" onChange={handleDateFilterChange} /> &nbsp;
                      <label htmlFor="dateFilter">Filter by Date</label>
                      <br />
                      {showDateField && (
                        <div>
                          <label>
                            <span className="text-style"></span>
                          </label>
                          &nbsp;&nbsp;
                          <input className="input-date" type="date" value={filterDate} onChange={handleDateChange} />
                          <GrRefresh className="refresh-list" onClick={getTestRecordsList} />
                        </div>
                      )}
                      <input type="checkbox" id="labNameFilter" name="labNameFilter" onChange={handleLabNameFilterChange} />
                      &nbsp;&nbsp;
                      <label htmlFor="labNameFilter">Filter by Hospital Name</label>
                      <br />
                      {showTextField && (
                        <div>
                          <label>
                            <span className="text-style"></span>
                          </label>
                          &nbsp;&nbsp;
                          <input className="input-date" type="text" placeholder="Enter Lab Name" value={filterLabName} onChange={handleLabNameChange} />
                          <GrRefresh className="refresh-list" onClick={getTestRecordsList} />
                        </div>
                      )}
                    </div>
                  )}
                </Col>
              ) : (
                ""
              )}
            </Row>
            {allergies?.length > 0  && <div style={{ marginTop: "-30px" }}>
              {allergies &&
                allergies?.map((allergy, index) => (
                  <div key={index} style={{ animationDelay: `${index * 0.5}s` }}>
                    <span style={{ color: "red", display: "inline-block", animation: "moveLeftRight 15s infinite" }}>
                      <b>Note :</b> Patient is having Allergy from {allergy.MedicineOrFood}, reaction is {allergy.Reaction} and effect is {allergy.effect}.
                    </span>
                  </div>
                ))}
            </div>
            }
          </>
        )}
        {currentTabIndex == 0 &&
          visibleRows?.slice(0, 1).map((dummyData) => {
            if (dummyData?.sampleData == true) {
              return (
                <SampleIllustration
                  key=""
                  titleHeader={strings?.sampleIllustrationTestReports?.title}
                  noteText1={strings?.sampleIllustrationTestReports?.theInformationPresented}
                  sampleText={strings?.sampleIllustrationTestReports?.sampleBasedOnDummyData}
                  noteText2={strings?.sampleIllustrationTestReports?.andIsShowJustCase}
                  anomaly={strings?.sampleIllustrationTestReports?.anomaly}
                  lastText={strings?.sampleIllustrationTestReports?.lastText}
                />
              );
            }
          })}
        {currentTabIndex == 0 && withVisitDate.length > 0 && ( 
          <>
            <MUITable data={visibleRows2} columns={columns} isLoading={isLoading} filterDate={filterDate} filterLabName={filterLabName} setSelectedRowData={setSelectedRowData} />
            <hr></hr>
          </>
        )}
        {/* {currentTabIndex == 0 && isLoading == false ? visibleRows?.length == 0 ? <div className="no-data-found">No Data Found</div> : "" : ""} */}
        
        {currentTabIndex == 0 && withoutVisitDate.length > 0 && (
          <>
            <h4 style={{marginBottom: "10px"}}>Test Report associated without Prescription</h4>
            <MUITable data={withoutVisitDate} columns={columnsWitoutPrescription} isLoading={isLoading} setSelectedRowData={setSelectedRowData} />
          </>
        )}
      </Box>
      {isReviewFormOpen && <ReviewsTabs currentTab={1} onRefresh={getTestRecordsList} selectedReport={selectedReport} setIsReviewFormOpen={setIsReviewFormOpen} isDummyData={isDummyData} />}
      {currentTabIndex == 0 && (
        <div>
          <InfoModel open={modalIsOpen} setOpen={closeModal} maxWidth={"md"} contentLabel="Image Popup" haveCloseIcon={true}>
            {!isLoadingDoc && (
              <div className="otherDocImg">
                {/* <img src={`${imageUrl}`} /> */}
                <object data={`${imageUrl}#toolbar=0&navpanes=0`} type="application/pdf" style={{ height: "100vh", width: "100%" }} scrolling="no">
                  <p>
                    It appears you don't have a PDF plugin for this browser. You can <a href={`${imageUrl}`}>click here to download the PDF file.</a>
                  </p>
                </object>
              </div>
            )}
            {isLoadingDoc && <Spinner />}
          </InfoModel>
        </div>
      )}
    </>
  );
}
