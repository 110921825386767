import { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  FormFeedback,
  FormControlLabel,
  FormText,
  Label,
  Input,
  Row,
  Col,
  Button,
  FormLabel,
  RadioGroup,
  Radio,
} from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

const DieticianGeneralInfo = () => {
  const [generalInfoData, setGeneralInfoData] = useState([]);
  const [labName, setLabName] = useState();
  const [yearStablish, setYearStablish] = useState();
  const [registrationNumber, setRegistrationNumber] = useState();
  const [numberOfLocation, setNumberOfLocation] = useState("");
  const [ownerName, setOwnerName] = useState();
  const [ownerPhone, setOwnerPhone] = useState();
  const [ownerEmail, setOwnerEmail] = useState();
  const [labAddress, setLabAddress] = useState();
  const [labPhoneNumber, setLabPhoneNumber] = useState();
  const [pincode, setPinCode] = useState();
  const [weekDayFrom, setWeekDayFrom] = useState("");
  const [weekEndFrom, setWeekEndFrom] = useState("");
  const [weekDayTo, setWeekDayTo] = useState("");
  const [weekEndTo, setWeekEndTo] = useState("");
  const [licenses1, setLicenses1] = useState();
  const [licenses2, setLicenses2] = useState();
  const [licenses3, setLicenses3] = useState();
  const [nablAccreditation, setNablAccreditation] = useState();
  const [isoCertificate, setIsoCertificate] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [fileName, setFileName] = useState(null);
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [accessNeeded, setAccessNeeded] = useState(false);
  const [sundayIsHoliday, setSundayIsHoliday] = useState(false);
  const [licenses, setLicenses] = useState([
    { license: "", documentLink: "" },
    { license: "", documentLink: "" },
    { license: "", documentLink: "" },
  ]);
  const [labPartnerCertificate, setLabPartnerCertificate] = useState([]);
  const [labPartnerSampleTest, setLabPartnerSampleTest] = useState([]);
  const [NABLreports, setNABLReports] = useState(false);
  const [ISOreports, setISOReports] = useState(false);
  const [uploadFlag, setUploadFlag] = useState(false);
  const [editedRows, seteditedRows] = useState([]);
  const [workDayStartTime, setWorkDayStartTime] = useState("");
  const [workDayEndTime, setWorkDayEndTime] = useState("");
  const [workDayWeekendStartTime, setWorkDayWeekendStartTime] = useState("");
  const [sampleCollectionService, setSampleCollectionService] = useState();
  const [workDayWeekendEndTime, setWorkDayWeekendEndTime] = useState("");
  const [serviceAreaPinCode, setServiceAreaPinCode] = useState([]);
  const [pinCodesString, setPinCodesString] = useState("");
  const [labMainAddress, setLabMainAddress] = useState();
  const [rows, setRows] = useState([
    {
      id: null,
      labDirectorName: "",
      phoneNumber: "",
      email: "",
      accessNeeded: false,
    },
  ]);
  const addRow = () => {
    setRows([
      ...rows,
      {
        id: null,
        labDirectorName: "",
        phoneNumber: "",
        email: "",
        accessNeeded: false,
      },
    ]);
  };
  const userDetails = JSON.parse(window.sessionStorage.getItem("userDetails"));
  const handleChange = (index, e, checked) => {
    console.log("e", e);
    const { name } = e.target;

    const newRows = [...rows];
    if (name.startsWith("accessNeeded")) {
      newRows[index]["accessNeeded"] = checked; // Update accessNeeded directly
    } else {
      newRows[index][name] = e.target.value; // Update other properties
    }

    console.log("new,", newRows);
    setRows(newRows);
  };

  const handleDirectorChange = (id, e) => {
    const newRows = [...generalInfoData?.labUsersDto];
    const index = newRows.findIndex((row) => row.id === id);
    if (index !== -1) {
      newRows[index][e.target.name] = e.target.value;
      seteditedRows(newRows);
    }
  };

  const handleDirector2Change = (id, fieldName, value) => {
    console.log("id", id, fieldName, value);
    const newRows = generalInfoData?.labUsersDto.map((row) => {
      if (row.id === id) {
        return { ...row, [fieldName]: value };
      }
      return row;
    });
    setGeneralInfoData((prevData) => ({
      ...prevData,
      labUsersDto: newRows,
    }));
    seteditedRows(newRows);
  };

  const handleLabName = (event) => {
    setLabName(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      LabName: false,
    }));
  };
  const handleYearStablish = (event) => {
    setYearStablish(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      yearStablish: false,
    }));
  };
  const handleRegistration = (event) => {
    setRegistrationNumber(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      registrationNumber: false,
    }));
  };
  const handleNumberofLocation = (event) => {
    setNumberOfLocation(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      numberOfLocation: false,
    }));
  };
  const handleOwnerName = (event) => {
    setOwnerName(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      ownerName: false,
    }));
  };
  const handleOwnerPhone = (event) => {
    setOwnerPhone(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      ownerPhone: false,
    }));
  };
  const handleOwnerEmail = (event) => {
    setOwnerEmail(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      ownerEmail: false,
    }));
  };
  const handleLabAddress = (event) => {
    setLabAddress(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      labAddress: false,
    }));
  };
  const handlelabPhoneNumber = (event) => {
    setLabPhoneNumber(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      labPhoneNumber: false,
    }));
  };
  const handlePinCode = (event) => {
    setPinCode(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      pincode: false,
    }));
  };
  const handleWeekDayFrom = (event) => {
    setWeekDayFrom(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      weekDayFrom: false,
    }));
  };
  const handleWeekDayTo = (event) => {
    setWeekDayTo(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      weekDayTo: false,
    }));
  };
  const handleWeekEndFrom = (event) => {
    setWeekEndFrom(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      weekEndFrom: false,
    }));
  };

  const handleWeekEndTo = (event) => {
    setWeekEndTo(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      weekEndTo: false,
    }));
  };
  const handleLicenses = (event) => {
    setLicenses1(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      licenses1: false,
    }));
  };
  const handleNablAccreditationChange = (value) => {
    setNablAccreditation(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      nablAccreditation: value === null,
    }));
  };
  const handleIsoCertification = (value) => {
    setIsoCertificate(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      isoCertificate: value === null,
    }));
  };
  const handleFileUpload = (value) => {
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      fileUpload: value === null || value.length === 0,
      reports: false,
    }));
  };

  const initialRows = generalInfoData?.labUsersDto?.map((user) => ({
    labDirectorName: user.labDirectorName,
    phoneNumber: user.phoneNumber,
    email: user.email,
    accessNeeded: user.accessNeeded,
  }));
  const handleSubmit = (e) => {
    e.preventDefault();
    let error = {};
    if (labName == undefined || labName == "" || labName == null) {
      error.LabName = true;
    }
    if (yearStablish == undefined || yearStablish == "") {
      error.yearStablish = true;
    }
    if (
      registrationNumber === undefined ||
      registrationNumber === "" ||
      registrationNumber === null
    ) {
      error.registrationNumber = true;
    }
    if (
      numberOfLocation === undefined ||
      numberOfLocation === "" ||
      numberOfLocation == null
    ) {
      error.numberOfLocation = true;
    }
    if (numberOfLocation == 0 || numberOfLocation == 1) {
      console.log("herer");
      if (workDayStartTime == "" || workDayStartTime == undefined) {
        error.workDayStartTime = true;
        error.sampleCollectionService = true;
      }
      if (workDayEndTime == "" || workDayEndTime == undefined) {
        error.workDayEndTime = true;
        error.sampleCollectionService = true;
      }
      if (
        workDayWeekendStartTime == "" ||
        workDayWeekendStartTime == undefined
      ) {
        error.workDayWeekendStartTime = true;
        error.sampleCollectionService = true;
      }
      if (workDayWeekendEndTime == "" || workDayWeekendEndTime == undefined) {
        error.workDayWeekendEndTime = true;
        error.sampleCollectionService = true;
      }
    }
    if (licenses[0]?.license === undefined || licenses[0]?.license === "") {
      // if (ownerName == undefined || ownerName == "") {
      //   error.ownerName = true;
      // }
      // if (ownerPhone == undefined || ownerPhone == "") {
      //   error.ownerPhone = true;
      // }
      // if (ownerEmail === undefined || ownerEmail === "") {
      //   error.ownerEmail = true;
      // }
      error.licenses1 = true;
    }
    if (nablAccreditation === undefined) {
      error.nablAccreditation = true;
    }
    if (isoCertificate === undefined) {
      error.isoCertificate = true;
    }
    if (fileUpload === undefined || fileUpload === null) {
      error.fileUpload = true;
    }
    if (labAddress === undefined || labAddress === "" || labAddress == null) {
      error.labAddress = true;
    }
    if (pincode === undefined || pincode === "" || pincode === null) {
      error.pincode = true;
    }
    if (
      weekDayFrom === "" ||
      weekDayFrom === undefined ||
      weekDayFrom == null
    ) {
      error.weekDayFrom = true;
    }
    if (weekDayTo === "" || weekDayTo === undefined || weekDayTo == null) {
      error.weekDayTo = true;
    }
    if (
      weekEndFrom === "" ||
      weekEndFrom === undefined ||
      weekEndFrom == null
    ) {
      error.weekEndFrom = true;
    }
    if (weekEndTo === "" || weekEndTo === undefined || weekEndTo == null) {
      error.weekEndTo = true;
    }
    if (
      labPhoneNumber === undefined ||
      labPhoneNumber === "" ||
      labPhoneNumber === null
    ) {
      error.labPhoneNumber = true;
    }

    if (nablAccreditation && nablCertificates.length === 0) {
      // if (nablAccreditation === true) {
      //   if (NABLreports === false) {
      //     if (labPartnerCertificate[0]?.documentLink === "")
      //     {
      //       error.NABLreports = true;
      //     }
      //   }
      // }
      // if (isoCertificate === true) {
      //    if (ISOreports === false) {
      //       if (labPartnerCertificate[0]?.documentLink === "") {
      //         error.ISOreports = true;
      //       }
      //    }
      // }
      error.NABLreports = true;
    }

    if (isoCertificate && isoCertificates.length === 0) {
      error.ISOreports = true;
    }
    if (ownerPhone?.length > 10 || ownerPhone?.length < 1) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Enter valid phone number",
      });
    }
    setFieldErrors(error);
    if (Object.keys(error).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      setFormSubmitted(true);
      const isRowEmpty = (row) => {
        return rows.length > 0 && rows[0].labDirectorName === "";
      };
      let combinedArray;

      if (isRowEmpty(rows)) {
        combinedArray = [...editedRows];
      } else {
        combinedArray = [...editedRows, ...rows];
      }
      const labMainAddress = {
        labPinCode: pincode, // Assuming this comes from another source
        labAddress: labAddress,
        labPhoneNumber: labPhoneNumber,
        workDayStartTime: weekDayFrom,
        workDayEndTime: weekDayTo,
        workDayWeekendStartTime: weekEndFrom,
        workDayWeekendEndTime: weekEndTo,
        serviceProvideWithSameAddress: serviceProvideWithSameAddress, // Adjust as needed
        labServiceAreaPinCode: {
          serviceAreaPinCode: pincodeRow.map((row) => row.serviceAreaPinCode),
          workDayStartTime: workDayStartTime, // Example data, adjust as needed
          workDayEndTime: workDayEndTime, // Example data, adjust as needed
          workDayWeekendStartTime: workDayWeekendStartTime, // Example data, adjust as needed
          workDayWeekendEndTime: workDayWeekendEndTime, // Example data, adjust as needed
        },
      };
      const formData = {
        labName: generalInfoData?.labName ? generalInfoData?.labName : labName,
        establishedYear: generalInfoData?.establishedYear
          ? generalInfoData?.establishedYear
          : yearStablish,
        registrationNumber: generalInfoData?.registrationNumber
          ? generalInfoData?.registrationNumber
          : registrationNumber,
        numberOfLocation: numberOfLocation,
        labUsers: combinedArray,
        labNabl: nablAccreditation,
        labIso: isoCertificate,
        // labPinCode: pincode,
        // labAddress: labAddress,
        // labPhoneNumber: labPhoneNumber,
        // workDayStartTime: weekDayFrom,
        // workDayEndTime: weekDayTo,
        // workDayWeekendStartTime: weekEndFrom,
        // workDayWeekendEndTime: weekEndTo,
        labMainAddress: labMainAddress,
        sundayIsHoliday: sundayIsHoliday,
      };
      AxiosInstance.put("lab-partner/general-info", formData)
        .then((response) => {
          setLabName("");
          setRegistrationNumber("");
          setYearStablish("");
          // setNumberOfLocation("");
          setOwnerName("");
          setOwnerPhone("");
          setOwnerEmail("");
          setLicenses1("");
          setLicenses2("");
          setLicenses3("");
          // setNablAccreditation("");
          // setIsoCertificate("");
          toast.success("Information submitted successfully");
          setTimeout(() => {
            // handleTabChange(null,1)
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleLicenseChange = (index, value) => {
    const newLicenses = [...licenses];
    newLicenses[index].license = value;
    setLicenses(newLicenses);
  };
  const handleFileChange = async (event, index) => {
    event.preventDefault();
    console.log("selected", index);
    const file = event.target.files[0];
    setSelectedFile(file);

    let fileCheck = fileName?.split(".")?.pop();
    if (
      fileCheck == "gif" ||
      fileCheck == "jfif" ||
      fileCheck == "csv" ||
      fileCheck == "xls"
    ) {
      toast.error("GIF, csv, xls and Jfif file are not allowed");
    } else {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("licenseNames", licenses[index].license);
      AxiosInstance.post("/lab-partner/licenses-upload", formData)
        .then((response) => {
          toast.success("Document Uploaded Successfully");
          setUploadFlag((prev) => !prev);
          setIsOpen(false);
          setSelectedFile(null);
          setFileName(null);
          GetLicenceInfo();
        })
        .catch((error) => {
          //setError("An
        });
    }
  };
  const handleISOChange = async (event, selectedRowData) => {
    event.preventDefault();
    const file = event.target.files[0];
    setSelectedFile(file);
    let fileCheck = fileName?.split(".")?.pop();
    if (
      fileCheck == "gif" ||
      fileCheck == "jfif" ||
      fileCheck == "csv" ||
      fileCheck == "xls"
    ) {
      toast.error("GIF, csv, xls and Jfif file are not allowed");
    } else {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("licenseNames", selectedRowData);
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        ISOreports: false,
      }));
      AxiosInstance.post("/lab-partner/licenses-upload", formData)
        .then((response) => {
          toast.success("Document Uploaded Successfully");
          setUploadFlag((prev) => !prev);
          setISOReports(true);
          setIsOpen(false);
          setSelectedFile(null);
          setFileName(null);
        })
        .catch((error) => {
          //setError("An
        });
    }
  };
  const handleNABLChange = async (event) => {
    event.preventDefault();
    const NABLCertificate = "NABLCertificate";
    const file = event.target.files[0];
    setSelectedFile(file);
    let fileCheck = fileName?.split(".")?.pop();
    if (
      fileCheck == "gif" ||
      fileCheck == "jfif" ||
      fileCheck == "csv" ||
      fileCheck == "xls"
    ) {
      toast.error("GIF, csv, xls and Jfif file are not allowed");
    } else {
      const formData = new FormData();
      formData.append("licenseNames", NABLCertificate);
      formData.append("file", file);
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        NABLreports: false,
      }));
      AxiosInstance.post("/lab-partner/licenses-upload", formData)
        .then((response) => {
          toast.success("Document Uploaded Successfully");
          setNABLReports(true);
          setUploadFlag((prev) => !prev);
          setIsOpen(false);
          setSelectedFile(null);
          setFileName(null);
        })
        .catch((error) => {
          console.log("error: ", error);
          //setError("An
        });
    }
  };
  const handleMultipleChange = async (event) => {
    event.preventDefault();
    const fileArray = Array.from(event.target.files);
    console.log("file: ", fileArray);
    // if (files.length < 8) {
    //   toast.error("Please select at least 8 files.");
    //   return;
    // }
    // setFileName(file.name);
    // setSelectedFile(file);
    let fileCheck = fileName?.split(".")?.pop();
    if (
      fileCheck == "gif" ||
      fileCheck == "jfif" ||
      fileCheck == "csv" ||
      fileCheck == "xls"
    ) {
      toast.error("GIF, csv, xls and Jfif file are not allowed");
    } else {
      const formData = new FormData();
      for (const file of fileArray) {
        formData.append("file", file);
      }
      AxiosInstance.post("/lab-partner/sample-test-upload", formData)
        .then((response) => {
          toast.success("Document Uploaded Successfully");
          setISOReports(true);
          setUploadFlag((prev) => !prev);
          setIsOpen(false);
          setSelectedFile(null);
          setFileName(null);
        })
        .catch((error) => {
          //setError("An
        });
    }
  };
  const GetLicenceInfo = async () => {
    await AxiosInstance.get(`${getLicenceInfo}`)
      .then((response) => {
        // const fetchedLicenses = response?.labPartnerLicense.length > 0 ?  response?.labPartnerLicense : licenses;
        // const sanitizedLicenses = fetchedLicenses.map(license =>
        //   ({ ...license, license: license.license === "undefined" ? '' : license.license })
        // );
        // setLicenses(sanitizedLicenses);

        const fetchedLicenses = response?.labPartnerLicense || [];
        const sanitizedLicenses = fetchedLicenses.map((license) => ({
          ...license,
          license: license.license === "undefined" ? "" : license.license,
        }));

        // Ensure there are exactly three licenses
        const licensesToSet = [...sanitizedLicenses];
        while (licensesToSet.length < 3) {
          licensesToSet.push({ license: "", documentLink: "" });
        }
        if (licensesToSet.length > 3) {
          licensesToSet.length = 3;
        }

        setLicenses(licensesToSet);

        // setLicenses(response?.labPartnerLicense);
        setLabPartnerSampleTest(response?.labPartnerSampleTest);
        setLabPartnerCertificate(response?.labPartnerCertificate);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };
  const GetGeneralInformation = async () => {
    await AxiosInstance.get(`${getGeneralInformation}`)
      .then((response) => {
        setLabMainAddress(response?.labMainAddress);
        setLabPhoneNumber(response?.labMainAddress?.labPhoneNumber);
        setPinCode(response?.labMainAddress?.labPinCode);
        setGeneralInfoData(response);
        setLabName(response?.labName);
        setYearStablish(response?.establishedYear);
        setRegistrationNumber(response?.registrationNumber);
        setNumberOfLocation(response?.numberOfLocation);
        setLabAddress(response?.labMainAddress?.labAddress);
        setLabPhoneNumber(response?.labMainAddress?.labPhoneNumber);
        setPinCode(response?.labMainAddress?.labPinCode);
        setServiceProvideWithSameAddress(
          response?.labMainAddress?.serviceProvideWithSameAddress
        );
        setWeekDayFrom(response?.labMainAddress?.workDayStartTime);
        setWeekDayTo(response?.labMainAddress?.workDayEndTime);
        setWeekEndFrom(response?.labMainAddress?.workDayWeekendStartTime);
        setWeekEndTo(response?.labMainAddress?.workDayWeekendEndTime);
        if (response?.labMainAddress?.serviceProvideWithSameAddress) {
          setWorkDayStartTime(
            response?.labMainAddress?.labServiceAreaPinCode?.workDayStartTime
          );
          setWorkDayEndTime(
            response?.labMainAddress?.labServiceAreaPinCode?.workDayEndTime
          );
          setWorkDayWeekendStartTime(
            response?.labMainAddress?.labServiceAreaPinCode
              ?.workDayWeekendStartTime
          );
          setWorkDayWeekendEndTime(
            response?.labMainAddress?.labServiceAreaPinCode
              ?.workDayWeekendEndTime
          );
          setServiceAreaPinCode(response?.labMainAddress);
        }
        setIsoCertificate(response?.labIso);
        setNablAccreditation(response?.labNabl);
        setSundayIsHoliday(response?.sundayIsHoliday);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };
  useEffect(() => {
    // GetGeneralInformation();
    // GetLicenceInfo();
    // setEmail(userDetails?.email);
    // setPhoneNumber(userDetails?.phoneNumber);
    // setFirstName(userDetails?.firstName);
    // setLastName(userDetails?.lastName);
    // setPinCodesString(
    //   serviceProvideWithSameAddress?.labServiceAreaPinCode?.serviceAreaPinCode.join(
    //     ", "
    //   )
    // );
  }, []);
  useEffect(() => {
    // GetLicenceInfo();
  }, [uploadFlag]);

  const IOSCertificate = "ISOCertificate";

  const nablCertificates = labPartnerCertificate
    .filter((certificate) => certificate.license === "NABLCertificate")
    .map((certificate) => certificate.documentLink);

  const isoCertificates = labPartnerCertificate
    .filter((certificate) => certificate.license === "ISOCertificate")
    .map((certificate) => certificate.documentLink);

  const [pincodeRow, setPincodeRow] = useState([{ serviceAreaPinCode: "" }]);
  const addPincode = () => {
    setPincodeRow([...pincodeRow, { serviceAreaPinCode: "" }]);
  };

  // Function to handle pin code change
  const handlePincodeChange = (index, e) => {
    const updatedPincodeRow = [...pincodeRow];
    updatedPincodeRow[index].serviceAreaPinCode = e.target.value;
    setPincodeRow(updatedPincodeRow);
  };
  const [serviceProvideWithSameAddress, setServiceProvideWithSameAddress] =
    useState();
  return (
    <>
      <Form
        style={{
          border: "1px solid lightgrey",
          padding: "15px",
          marginTop: "25px",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          fontSize: "18px",
        }}
      >
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label for="exampleEmail">
                <b>Name of Clinical Dietician/Nutritionist </b>
              </Label>
              <Input
                disabled={generalInfoData?.labName ? true : false}
                id=""
                name="name"
                value={
                  generalInfoData?.labName ? generalInfoData?.labName : labName
                }
                onChange={(e) => {
                  handleLabName(e);
                }}
                placeholder="Enter your Name"
                type="text"
                style={{
                  borderColor: fieldErrors.LabName ? "red" : "lightgrey",
                }}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="examplePassword">
                <b>Email</b>
              </Label>
              <Input
                id=""
                name="yearEstablished"
                value={
                  generalInfoData?.establishedYear
                    ? generalInfoData?.establishedYear
                    : yearStablish
                }
                onChange={(e) => {
                  handleYearStablish(e);
                }}
                placeholder=""
                type="email"
                style={{
                  borderColor: fieldErrors.yearStablish ? "red" : "lightgrey",
                }}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="exampleEmail">
                <b>Phone Number</b>
              </Label>
              <Input
                id=""
                name="registrationNumber"
                value={
                  generalInfoData?.registrationNumber
                    ? generalInfoData?.registrationNumber
                    : registrationNumber
                }
                onChange={(e) => {
                  handleRegistration(e);
                }}
                placeholder="Enter Phone Number"
                disabled={generalInfoData?.registrationNumber ? true : false}
                type="text"
                style={{
                  borderColor: fieldErrors.registrationNumber
                    ? "red"
                    : "lightgrey",
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row className="mt-4">
          <Col md={12}>
            <FormGroup>
              <b
                style={{
                  color: fieldErrors.sampleCollectionService ? "red" : "black",
                }}
              >
                Do you have a registered practice/organization for your business
                ?
              </b>
              &nbsp;&nbsp;&nbsp;
              <Input
                name="serviceProvideWithSameAddress"
                type="radio"
                checked={serviceProvideWithSameAddress === false}
                onChange={(e) => {
                  setServiceProvideWithSameAddress(false);
                }}
              />
              &nbsp;
              <Label check className="ml-1">
                No
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="serviceProvideWithSameAddress"
                type="radio"
                checked={serviceProvideWithSameAddress === true}
                onChange={(e) => {
                  setServiceProvideWithSameAddress(true);
                }}
              />
              &nbsp;
              <Label check className="ml-1">
                Yes
              </Label>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <FormGroup>
              <Label>
                <b>Name</b>
              </Label>
              <Input
                disabled
                id={`name`}
                name="phoneNumber"
                placeholder="Enter Name"
                type="text"
                // value={phoneNumber}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for={`phoneNumber`}>
                <b>Phone Number</b>
              </Label>
              <Input
                disabled
                id={`phoneNumber`}
                name="phoneNumber"
                placeholder="Enter Phone Number"
                type="number"
                value={phoneNumber}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for={`emailIndex`}>
                <b>Business Time</b>
              </Label>
              <Input
                disabled
                id={`email`}
                name="email"
                placeholder="Enter Email Id"
                type="time"
                value={email}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for={`phoneNumber`}>
                <b>Address</b>
              </Label>
              <Input
                disabled
                id={`phoneNumber`}
                name="phoneNumber"
                placeholder="Enter Phone Number"
                type="number"
                value={phoneNumber}
              />
            </FormGroup>
          </Col>
        </Row>

        <hr style={{ border: "1px solid #000000" }}></hr>
        {generalInfoData?.labUsers &&
          generalInfoData?.labUsers?.map((row, index) => (
            <>
              <Row key={index} className="mt-4">
                <Col md={3}>
                  <FormGroup>
                    <Label for={row.id}>
                      <b>Director / Founder / Owner Name</b>
                    </Label>
                    <Input
                      id={row.id}
                      name="labDirectorName"
                      placeholder="Enter Name"
                      type="text"
                      value={row.labDirectorName}
                      onChange={(e) => {
                        // handleChange(index, e);
                        // setOwnerName(e.target.value);
                        handleDirectorChange(row.id, e);
                        handleOwnerName(e);
                      }}
                      style={{
                        borderColor: fieldErrors.ownerName
                          ? "red"
                          : "lightgrey",
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for={row.id}>
                      <b>Phone Number</b>
                    </Label>
                    <Input
                      id={row.id}
                      name="phoneNumber"
                      placeholder="Enter Phone Number"
                      type="number"
                      value={row.phoneNumber}
                      onChange={(e) => {
                        // handleChange(index, e);
                        handleDirectorChange(row.id, e);
                        setOwnerPhone(e.target.value);
                        handleOwnerPhone(e);
                      }}
                      style={{
                        borderColor: fieldErrors.ownerPhone
                          ? "red"
                          : "lightgrey",
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for={row.id}>
                      <b>Email Id</b>
                    </Label>
                    <Input
                      id={row.id}
                      name="email"
                      placeholder="Enter Email Id"
                      type="email"
                      value={row.email}
                      onChange={(e) => {
                        // handleChange(index, e);
                        setOwnerEmail(e.target.value);
                        handleOwnerEmail(e);
                        handleDirectorChange(row.id, e);
                      }}
                      style={{
                        borderColor: fieldErrors.ownerEmail
                          ? "red"
                          : "lightgrey",
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for={`accessNeeded${index}`}>
                      <b>Does this person needs access to this account?</b>
                    </Label>
                    <br></br>
                    <Input
                      name={`accessNeeded${row.id}`}
                      type="radio"
                      checked={!row.accessNeeded}
                      value="false"
                      onChange={(e) => {
                        // handleChange(index, e);
                        setAccessNeeded(false);
                        handleDirector2Change(row.id, "accessNeeded", false);
                      }}
                    />
                    &nbsp;
                    <Label check className="ml-1">
                      No
                    </Label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Input
                      name={`accessNeeded${row.id}`}
                      type="radio"
                      checked={row.accessNeeded}
                      value="true"
                      onChange={(e) => {
                        // handleChange(index, e);
                        setAccessNeeded(true);
                        handleDirector2Change(row.id, "accessNeeded", true);
                      }}
                    />
                    &nbsp;
                    <Label check className="ml-1">
                      Yes
                    </Label>
                  </FormGroup>
                </Col>
                <Col md={1}>
                  <FormGroup></FormGroup>
                </Col>
              </Row>
            </>
          ))}
        <hr style={{ border: "1px solid #000000" }}></hr>
        <p style={{ color: "red" }}>
          Want to add more Directors then click on "Add Directors"{" "}
        </p>
        {rows &&
          rows?.map((row, index) => (
            <>
              <Row key={index} className="mt-4">
                <Col md={3}>
                  <FormGroup>
                    <Label for={`labDirectorName${index}`}>
                      <b>Director / Founder / Owner Name</b>
                    </Label>
                    <Input
                      id={`labDirectorName${index}`}
                      name="labDirectorName"
                      placeholder="Enter Name"
                      type="text"
                      value={row.labDirectorName}
                      onChange={(e) => {
                        handleChange(index, e);
                        setOwnerName(e.target.value);
                        handleOwnerName(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for={`phoneNumber${index}`}>
                      <b>Phone Number</b>
                    </Label>
                    <Input
                      id={`phoneNumber${index}`}
                      name="phoneNumber"
                      placeholder="Enter Phone Number"
                      type="number"
                      value={row.phoneNumber}
                      onChange={(e) => {
                        handleChange(index, e);
                        setOwnerPhone(e.target.value);
                        handleOwnerPhone(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for={`email${index}`}>
                      <b>Email Id</b>
                    </Label>
                    <Input
                      id={`email${index}`}
                      name="email"
                      placeholder="Enter Email Id"
                      type="email"
                      value={row.email}
                      onChange={(e) => {
                        handleChange(index, e);
                        setOwnerEmail(e.target.value);
                        handleOwnerEmail(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for={`accessNeeded${index}`}>
                      <b>Does this person needs access to this account?</b>
                    </Label>
                    <br></br>
                    <Input
                      name={`accessNeededDirector${index}`}
                      type="radio"
                      checked={!row.accessNeeded}
                      // value={row.accessNeeded}
                      onChange={(e) => {
                        handleChange(index, e, false);
                        setAccessNeeded(false);
                      }}
                    />
                    &nbsp;
                    <Label check className="ml-1">
                      No
                    </Label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Input
                      name={`accessNeeded${index}`}
                      type="radio"
                      checked={row.accessNeeded}
                      onChange={(e) => {
                        handleChange(index, e, true);
                        setAccessNeeded(true);
                      }}
                    />
                    &nbsp;
                    <Label check className="ml-1">
                      Yes
                    </Label>
                  </FormGroup>
                </Col>
                <Col md={1}>
                  <FormGroup>
                    {" "}
                    <Button
                      color="danger"
                      outline
                      style={{ marginTop: "33px", fontSize: "12px" }}
                      onClick={addRow}
                    >
                      Add Directors
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </>
          ))}
        <hr style={{ border: "1px solid #000000" }}></hr>
        {/* {labMainAddress &&
            Array.isArray(labMainAddress).map((index, row) => { */}
        <>
          <Row className="mt-4">
            <Col md={4}>
              <FormGroup>
                <Label>
                  <b>Main Office / Lab Address</b>
                </Label>
                <Input
                  name="labAddress"
                  placeholder="Enter Address"
                  value={labAddress}
                  type="text"
                  onChange={(e) => {
                    // handleAddressChange(addressIndex, e);
                    setLabAddress(e.target.value);
                    handleLabAddress(e);
                  }}
                  style={{
                    borderColor: fieldErrors.labAddress ? "red" : "lightgrey",
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>
                  <b>Phone Number</b>
                </Label>
                <Input
                  name="labPhoneNumber"
                  placeholder="Enter Phone Number of Lab"
                  value={labPhoneNumber}
                  type="number"
                  onChange={(e) => {
                    // handleAddressChange(addressIndex, e);
                    setLabPhoneNumber(e.target.value);
                    handlelabPhoneNumber(e);
                  }}
                  style={{
                    borderColor: fieldErrors.labPhoneNumber
                      ? "red"
                      : "lightgrey",
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>
                  <b>Pin Code</b>
                </Label>
                <Input
                  name="labPinCode"
                  placeholder="Enter PinCode"
                  value={pincode}
                  onChange={(e) => {
                    // handleAddressChange(addressIndex, e);
                    handlePinCode(e);
                  }}
                  type="number"
                  style={{
                    borderColor: fieldErrors.pincode ? "red" : "lightgrey",
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={3}>
              <FormGroup>
                <Label>
                  <b>Working Timing From</b> (Monday - Friday){" "}
                </Label>
                <Input
                  name="labPinCode"
                  placeholder="Enter PinCode"
                  value={weekDayFrom}
                  onChange={(e) => {
                    // handleWeekDayTimeFrom(addressIndex, e);
                    handleWeekDayFrom(e);
                  }}
                  type="time"
                  style={{
                    borderColor: fieldErrors.weekDayFrom ? "red" : "lightgrey",
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>
                  <b>Working Timing To</b> (Monday - Friday){" "}
                </Label>
                <Input
                  name="labPinCode"
                  placeholder="Enter PinCode"
                  value={weekDayTo}
                  onChange={(e) => {
                    // handleAddressChange(addressIndex, e);
                    handleWeekDayTo(e);
                  }}
                  type="time"
                  style={{
                    borderColor: fieldErrors.weekDayTo ? "red" : "lightgrey",
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>
                  <b>Working Timing From</b> (Saturday & Sunday)
                </Label>
                <Input
                  name="labPinCode"
                  placeholder="Enter PinCode"
                  value={weekEndFrom}
                  onChange={(e) => {
                    // handleAddressChange(addressIndex, e);
                    handleWeekEndFrom(e);
                  }}
                  type="time"
                  style={{
                    borderColor: fieldErrors.weekEndFrom ? "red" : "lightgrey",
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>
                  <b>Working Timing To</b> (Saturday & Sunday)
                </Label>
                <Input
                  name="labPinCode"
                  placeholder="Enter Pincode"
                  value={weekEndTo}
                  onChange={(e) => {
                    // handleAddressChange(addressIndex, e);
                    handleWeekEndTo(e);
                  }}
                  type="time"
                  style={{
                    borderColor: fieldErrors.weekEndTo ? "red" : "lightgrey",
                  }}
                />
              </FormGroup>
            </Col>
            <Row>
              <Col>
                <b
                  style={{
                    color: fieldErrors.sampleCollectionService
                      ? "red"
                      : "black",
                  }}
                >
                  Provide sample collection service from here for different
                  pincode ?
                </b>
                &nbsp;&nbsp;&nbsp;
                <Input
                  name="serviceProvideWithSameAddress"
                  type="radio"
                  checked={serviceProvideWithSameAddress === false}
                  onChange={(e) => {
                    setServiceProvideWithSameAddress(false);
                  }}
                />
                &nbsp;
                <Label check className="ml-1">
                  No
                </Label>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Input
                  name="serviceProvideWithSameAddress"
                  type="radio"
                  checked={
                    (numberOfLocation == 0 || numberOfLocation == 1) &&
                    serviceProvideWithSameAddress === true
                  }
                  onChange={(e) => {
                    setServiceProvideWithSameAddress(true);
                  }}
                />
                &nbsp;
                <Label check className="ml-1">
                  Yes
                </Label>
              </Col>
            </Row>
            {serviceProvideWithSameAddress == true && (
              <>
                <Row>
                  <Col md={7} className="mt-3">
                    <FormGroup>
                      {/* <Label for={`LocPincode${addressIndex}`}> */}
                      <b>
                        List all PIN Codes where this location will provide home
                        collection service
                      </b>
                      {/* </Label> */}
                    </FormGroup>
                  </Col>
                </Row>
                {/* {Array.isArray(serviceAreaPinCode?.labServiceAreaPinCode?.serviceAreaPinCode) &&
                    serviceAreaPinCode?.labServiceAreaPinCode?.serviceAreaPinCode?.map((row, index) => (
                      <>
                        <Row key={index}> */}
                {/* {console.log("row", row)}
                          <Col md="4"> */}
                {/* <FormGroup> */}
                {/* <Input
                                // id={`locPincode${index}`}
                                name={`locPincode${index}`}
                                placeholder="Enter Pincode"
                                value={row}
                                type="number"
                                onChange={(e) => {
                                  handlePincodeChange(index, e);
                                  setServiceAreaPinCode(e.target.value);
                                  // handleLocPinCode(e);
                                }}
                                style={{
                                  borderColor: fieldErrors ? "red" : "lightgrey",
                                }}
                              /> */}
                {/* </FormGroup>
                          </Col>
                          <Col md={2}>
                            <FormGroup> */}
                {/* <Button color="danger" outline style={{ fontSize: "15px", width: "75%" }} onClick={() => addPincode(addressIndex)}>
                              Add more pincode
                            </Button> */}
                {/* </FormGroup>
                          </Col>
                        </Row>
                      </> */}
                {/* // ))} */}
                {pincodeRow &&
                  pincodeRow.map((row, index) => (
                    <>
                      <Row key={index}>
                        <Col md="4">
                          <FormGroup>
                            <Input
                              id={`locPincode${index}`}
                              name="locPincode"
                              placeholder="Enter Pincode"
                              value={row.pincode}
                              type="number"
                              onChange={(e) => {
                                handlePincodeChange(index, e);
                                setServiceAreaPinCode(e.target.value);
                                // handleLocPinCode(e);
                              }}
                              style={{
                                borderColor: fieldErrors.locPincode
                                  ? "red"
                                  : "lightgrey",
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <Button
                              color="danger"
                              outline
                              style={{ fontSize: "15px", width: "75%" }}
                              onClick={addPincode}
                            >
                              Add more pincode
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  ))}
                <div>
                  {/* Display pin codes separated by comma */}
                  <b>Pin Codes where service is provided : </b>{" "}
                  {serviceAreaPinCode?.labServiceAreaPinCode?.serviceAreaPinCode.join(
                    ", "
                  )}
                </div>
                <Row className="mt-5">
                  <Col md={3}>
                    <FormGroup>
                      <Label>
                        <b>Sample Collection Timing From</b>
                        <br></br> (Monday - Friday)
                      </Label>
                      <Input
                        // for={`weekCollectionTimeFrom${addressIndex}`}
                        name="weekCollectionTimeFrom"
                        placeholder="Enter PinCode"
                        value={workDayStartTime}
                        onChange={(e) => {
                          // handleAddressChange(addressIndex, e);
                          setWorkDayStartTime(e.target.value);
                          // handleWeekCollectionTimeFrom(e);
                        }}
                        type="time"
                        style={{
                          borderColor: fieldErrors.workDayStartTime
                            ? "red"
                            : "lightgrey",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>
                        <b>Sample Collection Timing To</b>
                        <br></br> (Monday - Friday){" "}
                      </Label>
                      <Input
                        // for={`weekCollectionTimeTo${addressIndex}`}
                        name="weekCollectionTimeTo"
                        placeholder="Enter PinCode"
                        value={workDayEndTime}
                        onChange={(e) => {
                          // handleAddressChange(addressIndex, e);
                          setWorkDayEndTime(e.target.value);
                          // handleWeekCollectionTimeTo(e);
                        }}
                        type="time"
                        style={{
                          borderColor: fieldErrors.workDayEndTime
                            ? "red"
                            : "lightgrey",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>
                        <b>Sample Collection Timing From</b>
                        <br></br> (Saturday & Sunday)
                      </Label>
                      <Input
                        // for={`weekEndCollectionTimeFrom${addressIndex}`}
                        name="weekEndCollectionTimeFrom"
                        placeholder="Enter PinCode"
                        value={workDayWeekendStartTime}
                        onChange={(e) => {
                          // handleAddressChange(addressIndex, e);
                          setWorkDayWeekendStartTime(e.target.value);
                          // handleWeekEndCollectionTimeFrom(e);
                        }}
                        type="time"
                        style={{
                          borderColor: fieldErrors.workDayWeekendStartTime
                            ? "red"
                            : "lightgrey",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>
                        <b>Sample Collection Timing To</b> <br></br>(Saturday &
                        Sunday)
                      </Label>
                      <Input
                        // for={`weekEndCollectionTimeTo${addressIndex}`}
                        name="weekEndCollectionTimeTo"
                        placeholder="Enter PinCode"
                        value={workDayWeekendEndTime}
                        onChange={(e) => {
                          // handleAddressChange(addressIndex, e);
                          setWorkDayWeekendEndTime(e.target.value);
                          // handleWeekEndCollectionTimeTo(e);
                        }}
                        type="time"
                        style={{
                          borderColor: fieldErrors.workDayWeekendEndTime
                            ? "red"
                            : "lightgrey",
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}

            <Col>
              <b>Sunday is Holiday?</b> &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="sundayHoliday"
                type="radio"
                checked={sundayIsHoliday === false}
                onChange={(e) => {
                  setSundayIsHoliday(false);
                }}
              />
              &nbsp;
              <Label check className="ml-1">
                No
              </Label>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="sundayHoliday"
                type="radio"
                checked={sundayIsHoliday === true}
                onChange={(e) => {
                  setSundayIsHoliday(true);
                }}
              />
              &nbsp;
              <Label check className="ml-1">
                Yes
              </Label>
            </Col>
          </Row>
        </>
        {/* })} */}
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row className="mt-4">
          <Col md={12}>
            <FormGroup style={{ marginTop: "25px" }}>
              <Label for="exampleEmail">
                <b>What Licenses you carry for your Establishment:</b>
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {licenses.map((license, index) => (
            <Col md={4} key={index}>
              <FormGroup>
                <Label>
                  <b>License No. {index + 1}</b>
                </Label>
                <Input
                  name={`Licenses${index + 1}`}
                  value={license.license}
                  onChange={(e) => {
                    handleLicenseChange(index, e.target.value);
                    handleLicenses(e);
                  }}
                  placeholder="Enter License Name"
                  type="text"
                  style={{
                    borderColor:
                      index == 0
                        ? fieldErrors.licenses1
                          ? "red"
                          : "lightgrey"
                        : "lightgrey",
                  }}
                />
                <label
                  htmlFor={`licenses${index + 1}`}
                  style={{
                    cursor: "pointer",
                    color: "#5353e9",
                    textDecoration: "underline",
                  }}
                >
                  Attach License {index + 1}
                </label>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .pdf, .png"
                  id={`licenses${index + 1}`}
                  className="file-upload-input"
                  name={`licenses${index + 1}`}
                  style={{ display: "none" }}
                  onChange={(event) => handleFileChange(event, index)}
                />
                {license.documentLink && (
                  <div>
                    <p>{license.documentLink}</p>
                  </div>
                )}
              </FormGroup>
            </Col>
          ))}
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row style={{ marginTop: "30px" }} className="mt-4">
          <Col md={4}>
            <FormGroup>
              <Label
                for="examplePassword"
                style={{
                  color: fieldErrors.nablAccreditation ? "red" : "inherit",
                }}
              >
                <b>Do you have NABL Accreditation?</b>
              </Label>
            </FormGroup>
          </Col>
          <Col md={8}>
            <div className="d-flex align-items-center">
              <FormGroup check className="form-inline mr-3">
                <Input
                  name="nablAccreditation"
                  type="radio"
                  checked={nablAccreditation === true}
                  onChange={() => {
                    setNablAccreditation(true);
                    handleNablAccreditationChange(true);
                  }}
                />
                <Label check className="mr-3 ml-1">
                  Yes
                </Label>
              </FormGroup>
              <FormGroup
                check
                className="form-inline"
                style={{ marginLeft: "5%" }}
              >
                <Input
                  name="nablAccreditation"
                  type="radio"
                  checked={nablAccreditation === false}
                  onChange={() => {
                    setNablAccreditation(false);
                    handleNablAccreditationChange(false);
                  }}
                />
                <Label check className="ml-1">
                  No
                </Label>
              </FormGroup>
              {nablAccreditation && (
                <>
                  <span>
                    <label
                      htmlFor="nablAccreditation"
                      style={{
                        cursor: "pointer",
                        color: "rgb(83, 83, 233)",
                        textDecoration: "underline",
                        marginLeft: "20px",
                        color: fieldErrors.NABLreports ? "red" : "blue",
                      }}
                    >
                      Attach NABL Certificate Copy
                    </label>
                  </span>
                  <div>
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .pdf, .png"
                      id="nablAccreditation"
                      className="file-upload-input"
                      name="nablAccreditation"
                      // onChange={(event) => handleFileSubmit(event, row.id)}
                      style={{ display: "none" }}
                      onChange={(event) => handleNABLChange(event)}
                    />
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  {nablCertificates.length > 0
                    ? nablCertificates.map((link, index) => <p>{link}</p>)
                    : ""}
                </>
              )}
            </div>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row className="mt-4">
          <Col md={4}>
            <FormGroup>
              <Label
                for="examplePassword"
                style={{
                  color: fieldErrors.isoCertificate ? "red" : "inherit",
                }}
              >
                <b>Do you have ISO 9000 Certification?</b>
              </Label>
            </FormGroup>
          </Col>
          <Col md={8}>
            <div className="d-flex align-items-center">
              <FormGroup check className="form-inline mr-3">
                <Input
                  name="isoCertificate"
                  type="radio"
                  checked={isoCertificate === true}
                  onChange={() => {
                    setIsoCertificate(true);
                    handleIsoCertification(true);
                  }}
                />
                <Label check className="mr-3 ml-1">
                  Yes
                </Label>
              </FormGroup>
              <FormGroup
                check
                className="form-inline"
                style={{ marginLeft: "5%" }}
              >
                <Input
                  name="isoCertificate"
                  type="radio"
                  checked={isoCertificate === false}
                  onChange={() => {
                    setIsoCertificate(false);
                    handleIsoCertification(false);
                  }}
                />
                <Label check className="ml-1">
                  No
                </Label>
              </FormGroup>
              {isoCertificate && (
                <>
                  <span>
                    <label
                      htmlFor="isoCertificate"
                      style={{
                        cursor: "pointer",
                        color: "rgb(83, 83, 233)",
                        textDecoration: "underline",
                        marginLeft: "20px",
                        color: fieldErrors.ISOreports ? "red" : "blue",
                      }}
                    >
                      Attach ISO 9000 Certificate Copy
                    </label>
                  </span>{" "}
                  <div>
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .pdf, .png"
                      id="isoCertificate"
                      className="file-upload-input"
                      name="isoCertificate"
                      // onChange={(event) => handleFileSubmit(event, row.id)}
                      style={{ display: "none" }}
                      onChange={(event) =>
                        handleISOChange(event, IOSCertificate)
                      }
                    />
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  {isoCertificates.length > 0
                    ? isoCertificates.map((link, index) => <p>{link}</p>)
                    : ""}
                </>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col style={{ marginTop: "6%" }}>
            <p
              style={{
                padding: "1%",
                color: fieldErrors.reports ? "red" : "inherit",
              }}
            >
              Please submit <b>7 - 8 sample copies</b> of your past{" "}
              <b>Test Reports</b> of your patients. We need this reports to
              train our AI Model with your Reports. You may <b>hide</b> the
              patient information (Attach one at a time). &nbsp;&nbsp;&nbsp;
              <span>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .pdf, .png"
                  id="fileUpload"
                  className="file-upload-input"
                  name="file"
                  onChange={(event) => {
                    handleMultipleChange(event), handleFileUpload(event);
                  }}
                  multiple
                />
                {isLoading == true ? (
                  <div>
                    <Spinner />
                    <br />
                    <p>Please wait your prescription is uploading</p>
                  </div>
                ) : (
                  ""
                )}
              </span>
              <br></br>
              <div>
                <b>Reports : </b>
                {labPartnerSampleTest?.map((sampleTestObj, index) => (
                  <span key={index}>
                    {sampleTestObj.sampleTestLink}
                    {index !== labPartnerSampleTest?.length - 1 && ", "}
                  </span>
                ))}
              </div>
              {/* })} */}
            </p>
            <p style={{ display: "flex", marginLeft: "10px" }}>
              <b>Note :</b> If you have any query regarding this, please reach
              out to our Marketing Manager at :{" "}
              <p style={{ color: "blue" }}> &nbsp;+91 9082950730</p>
            </p>
          </Col>
        </Row>
      </Form>
      <center>
        <Button
          onClick={handleSubmit}
          style={{
            marginTop: "4%",
            width: "15%",
            backgroundColor: "rgb(83, 83, 233)",
          }}
        >
          Save
        </Button>
      </center>
    </>
  );
};

export default DieticianGeneralInfo;
