import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import Box from "@mui/material/Box";
import MUITable from "../../shared/MUITable";
import Spinner from "../../shared/Spinner";
const columns = [
  {
    id: "dateRecommended",
    label: "Date Recommended",
    isDate: true,
  },
  {
    id: "medicalCondition",
    label: "Medical Condition",
  },
  {
    id: "lrid",
    label: "LRID",
  },
  {
    id: "preventiveTest",
    label: "Preventive Test",
  },
  {
    id: "testName",
    label: "Test Name",
  },
  {
    id: "attachReport",
    label: "Attach Test Report",
    isLink: true,
  },
];
const PreventiveRecord = () => {
   const [filterDate, setFilterDate] = useState("");
   const [testResult, setTestResult] = useState();
   const [isLoading, setIsLoading] = useState(false);
   const [isOpen, setIsOpen] = useState(false);
   const [selectedRowData, setSelectedRowData] = useState();
   const [recordsListData, setRecordsListData] = useState([]);
   const [loading, setLoading] = useState(false);
   const [selectedFile, setSelectedFile] = useState();
   const [isReviewFormOpen, setIsReviewFormOpen] = useState(false);
   const [isDummyData, setIsDummyData] = useState(false);
   const [lrid, setLrid] = useState("");
    const [fileName, setFileName] = useState(null);
    const [allergies, setAllergies] = useState([]);

   useEffect(() => {
     getTestRecordsList();
     AxiosInstance.get("/test-report/getPreventiveTestRecordStatus")
       .then((response) => {})
       .catch((error) => {
         console.log(error);
       });
     AxiosInstance.get("form/personalFamilyHistory")
       .then((response) => {
         const allergiesArray = JSON.parse(response.allergies);
         setAllergies(allergiesArray);
       })
       .catch((error) => {
         console.log(error);
     });
   }, []);

   let checkLrid = [];
   let updateStatus;
   const getTestRecordsList = async () => {
     AxiosInstance.get("/test-report/getPreventiveTestRecordStatus")
       .then((response) => {
         checkLrid = response.map((obj) => obj.lrid);
       })
       .catch((error) => {
         console.log(error);
       });
     AxiosInstance.get("riskAssessment/getLifestylePreventiveCareServices").then((response) => {
       let check = response.map((obj) => obj.lrid);
     });
     try {
       setIsLoading(true);
       const response = await AxiosInstance.get("riskAssessment/getLifestylePreventiveCareServices");
       const responseData = response;
       let check = response.map((obj) => obj.lrid);
       updateStatus = check.filter((id) => checkLrid.includes(id)) ? "Complete" : "Incomplete";
       const testReportList = responseData?.map((obj) => {
         const isComplete = check.some((id) => checkLrid.includes(obj.lrid));
         const taskStatus = isComplete ? "Complete" : "Incomplete";
         const setLridNew = obj.lrid;
         setLrid(setLridNew);
         return {
           dateRecommended: obj.dateRecommended,
           medicalCondition: obj.medicalCondition,
           lrid: obj.lrid,
           preventiveTest: obj.preventiveTest,
           attachReport : "View Report",
           testName: obj.bookForTest,
        //    attachReport: (
        //      <div>
        //        <label htmlFor="fileUpload" style={{ cursor: "pointer", color: "#5353e9", textDecoration: "underline" }}>
        //          View Report
        //        </label>
        //        {isLoading == true ? (
        //          <div>
        //            <Spinner />
        //            <br />
        //            <p>Please wait your prescription is uploading</p>
        //          </div>
        //        ) : (
        //          ""
        //        )}
        //      </div>
        //    ),
        //    taskStatus: taskStatus,
         };
       });
       setRecordsListData(testReportList);
       setIsLoading(false);
     } catch (error) {
       console.error("Error fetching test records:", error);
       setIsLoading(false);
     }
   };
   const handleFileChange = async (event, selectedRowData) => {
     event.preventDefault();
     const file = event.target.files[0];
     const LRID = selectedRowData.rowData.lrid;
     setSelectedFile(file);
     let fileCheck = fileName?.split(".")?.pop();
     if (fileCheck == "gif" || fileCheck == "jfif" || fileCheck == "csv" || fileCheck == "xls") {
       toast.error("GIF, csv, xls and Jfif file are not allowed");
     } else {
       const formData = new FormData();
       formData.append("file", file);
       formData.append("lrid", LRID);
       setLoading(true);
       AxiosInstance.post("/test-report/uploadPreventiveReport", formData)
         .then((response) => {
           toast.success("Document Uploaded Successfully");
           setUploadFlag(true);
           setIsOpen(false);
           setLoading(false);
           setSelectedFile(null);
           setFileName(null);
         })
         .catch((error) => {
           //setError("An error occurred while uploading the file.");
           setLoading(false);
         });
     }
   };
   // };
   const onInputClick = (event) => {};
   return (
     <>
       <div style={{marginBottom:"20px"}}>
         {allergies && allergies?.map((allergy, index) => (
           <div key={index} style={{ animationDelay: `${index * 0.5}s` }}>
             <span style={{ color: "red", display: "inline-block", animation: "moveLeftRight 15s infinite" }}>
               <b>Note :</b> Patient is having Allergy from {allergy.MedicineOrFood}, reaction is {allergy.Reaction} and effect is {allergy.effect}.
             </span>
           </div>
         ))}
       </div>
       {isReviewFormOpen && <ReviewsTabs currentTab={1} onRefresh={getTestRecordsList} selectedReport={selectedReport} setIsReviewFormOpen={setIsReviewFormOpen} isDummyData={isDummyData} />}
       <Box sx={{ width: "100%" }}>
         <Row style={{ alignItems: "end" }}>
           <Col md={8}>
             <div style={{ display: "none" }}>
               <input
                 type="file"
                 accept=".jpg, .jpeg, .pdf, .png"
                 id="fileUpload"
                 className="file-upload-input"
                 name="file"
                 onChange={(event) => handleFileChange(event, selectedRowData)}
                 onClick={(event) => onInputClick(selectedRowData)}
               />
               {isLoading == true ? (
                 <div>
                   <Spinner />
                   <br />
                   <p>Please wait your prescription is uploading</p>
                 </div>
               ) : (
                 <>Attach</>
               )}
             </div>
           </Col>
           <Col md={4} className="upload-button"></Col>
         </Row>
       </Box>
       {!!recordsListData && <MUITable data={recordsListData} columns={columns} isLoading={isLoading} filterDate={filterDate} setSelectedRowData={setSelectedRowData} />}
     </>
   );
};

export default PreventiveRecord;
