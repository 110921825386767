/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { IconFilledEvents } from "../IconFilledEvents";
import { IconEvents } from "../IconEvents";
import { IconOverview } from "../IconOverview";
import "./NavCaseHistory.css";
import { NavLink } from "react-router-dom";

export const NavCaseHistory = ({ property1, style, frameStyle, iconFilledOverviewIconFilledOverview = "/img/filledBeaker.png", overviewStyle }) => {
  return (
    <div className={`navs-events ${property1}`} style={style}>
      <div className="menu-item">
        <div className="frame" style={frameStyle}>
          <div className="frame-wrapper">
            <div className="div">
              {property1 === "default" && <IconFilledEvents iconFilledEvents="/img/icon-filled-events-3.png" />}

              {property1 === "variant-2" && <IconEvents iconEvents="/img/icon-events.png" />}

              <div className="component-8">
                <div className="menu-item-8">
                  <div className="frame-49">
                    <div className="frame-50">
                      <div className="frame-51">
                        <NavLink to="/case-history" className="text-wrapper-106 menu-item overview" style={{color:"#888888"}}>
                          Case History
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NavCaseHistory.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  // iconFilledOverviewIconFilledOverview: PropTypes.string,
};
